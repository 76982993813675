import React, { FC } from 'react';

import homeBannerTypes from './homeBannerTypes';
import HomeBannerSlider from './HomeBannerSlider';

import { IHomeBannerProps } from './model';
import './HomeBanner.scss';

const HomeBanner: FC<IHomeBannerProps> = ({ topBanner }) => {
  const isSliderType = topBanner?.length > 1;
  const componentType = topBanner[0].properties.bannerType?.[0];
  const HomeBannerComponent = homeBannerTypes[componentType] || homeBannerTypes.default;

  return isSliderType ? (
    <HomeBannerSlider topBanner={topBanner} />
  ) : (
    <HomeBannerComponent bannerContent={topBanner[0].properties} />
  );
};

export default HomeBanner;
